@import '@angular/material/theming';
@import "variables.scss";
// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

* {
	color: rgba(255, 255, 255, 0.7);
	font-family: Roboto, "Helvetica Neue", sans-serif;

	mat-icon {
		font-family: 'Material Icons';
	}

	mat-error * {
		$warn: map-get($app-theme, warn);
		$underline-color-warn: mat-color($warn);
		color: $underline-color-warn;
	}
}

@include angular-material-theme($app-dark-theme);

.mat-error ts-control-error-component {
	$warn: map-get($app-dark-theme, warn);
	$underline-color-warn: mat-color($warn);

	color: $underline-color-warn;
}

.light-theme {
	* {
		color: rgba(0, 0, 0, 0.7);
		font-family: Roboto, "Helvetica Neue", sans-serif;
		box-sizing: border-box;

		mat-icon {
			font-family: 'Material Icons';
		}

		mat-error * {
			$warn: map-get($app-theme, warn);
			$underline-color-warn: mat-color($warn);
			color: $underline-color-warn;
		}
	}

	.mat-step-header {
		.mat-step-icon.mat-step-icon {
			$accent: map-get($app-theme, accent);
			$primary: map-get($app-theme, background);
			background-color: mat-color($accent);

			* {
				color: white;
			}
		}

		.mat-step-icon-not-touched.mat-step-icon-not-touched {
			$accent: map-get($app-theme, accent);
			$primary: map-get($app-theme, background);
			background-color: mat-color($accent, default, 0.6);

			* {
				color: white;
			}
		}
	}

	@include angular-material-theme($app-theme);

	.mat-error ts-control-error-component * {
		$warn: map-get($app-theme, warn);
		$underline-color-warn: mat-color($warn);

		color: $underline-color-warn;
	}
}

.light-theme mat-progress-spinner.light-grey {
	svg {
		circle {
			stroke: rgba(0, 0, 0, .2);
		}
	}
}
