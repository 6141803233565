@use '@angular/material' as mat;

$gronefeld-orange: (
	50 : #ffeee2,
	100 : #ffd5b7,
	200 : #ffb987,
	300 : #fe9d56,
	400 : #fe8832,
	500 : #fe730e,
	600 : #fe6b0c,
	700 : #fe600a,
	800 : #fe5608,
	900 : #fd4304,
	A100 : #ffffff,
	A200 : #fff3f1,
	A400 : #ffcbbe,
	A700 : #ffb6a4,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #000000,
		500 : #000000,
		600 : #000000,
		700 : #000000,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #000000,
		A700 : #000000,
	)
);

$gronefeld-grey: (
	50 : #e5e5e5,
	100 : #bdbdbd,
	200 : #919191,
	300 : #656565,
	400 : #444444,
	500 : #232323,
	600 : #1f1f1f,
	700 : #1a1a1a,
	800 : #151515,
	900 : #0c0c0c,
	A100 : #e76c6c,
	A200 : #e04040,
	A400 : #ec0000,
	A700 : #d30000,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #ffffff,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);
$md-red: (
	50 : #fbe0e2,
	100 : #f5b3b8,
	200 : #ef8088,
	300 : #e94d58,
	400 : #e42635,
	500 : #df0011,
	600 : #db000f,
	700 : #d7000c,
	800 : #d2000a,
	900 : #ca0005,
	A100 : #fff2f2,
	A200 : #ffbfc0,
	A400 : #ff8c8d,
	A700 : #ff7374,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #000000,
		A700 : #000000,
	)
);
$md-green: (
	50: #e8f5e9,
	100: #c8e6c9,
	200: #a5d6a7,
	300: #81c784,
	400: #66bb6a,
	500: #4caf50,
	600: #43a047,
	700: #388e3c,
	800: #2e7d32,
	900: #1b5e20,
	A100: #b9f6ca,
	A200: #69f0ae,
	A400: #00e676,
	A700: #00c853,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #000000,
		A400 : #000000,
		A700 : #000000,
	)
);

//Define pallet for app theme.
$app-primary: mat.define-palette($gronefeld-grey);
$app-accent: mat.define-palette($gronefeld-orange);
$app-warn: mat.define-palette($md-red);

// Custom color variables for use in components.
$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);
$app-dark-theme: mat.define-dark-theme($app-primary, $app-accent, $app-warn);

/**
The properties contained within the $app-theme / $app-dark-theme
Accessible through the map-get() mixin function.

foreground:
  base:              white,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba(white, 0.3),
  disabled-text:     $light-disabled-text,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-secondary-text,
  icon:              white,
  icons:             white,
  text:              white,
  slider-min:        white,
  slider-off:        rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),

background:
  status-bar: black,
  app-bar:    map_get($mat-grey, 900),
  background: #303030,
  hover:      rgba(white, 0.04),
  card:       map_get($mat-grey, 800),
  dialog:     map_get($mat-grey, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($mat-grey, 800),
  focused-button: $light-focused,
  selected-button: map_get($mat-grey, 900),
  selected-disabled-button: map_get($mat-grey, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get($mat-grey, 700),
  disabled-list-option: black,
 */
